import React from 'react';
import styles from './about.module.css';
import arrowLight from '../../assets/arrow-light.svg';
import arrowDark from '../../assets/arrow-dark.svg';

const about = (props) => {
  const arrow = props.mode === 'light' ? arrowLight : arrowDark;

  return (
    <div id='about' className={styles.About}>
      <div className={styles.AboutContainer}>
        <h2 mode={props.mode} className={styles.Title + ' text'}>
          About me
        </h2>
        <p className={styles.Note}>
          I love to build! Building things and seeing through them to completion has always resonated with me. 
          <br />
          <br />
          As an embedded Software Engineer, I enjoy the sense of control that comes with programming on physical devices.
          It is unlike most things in life, in that the process can be very methodical. 
          When tangible objects do the right thing, it is because of you. When encountering bugs, it is also because of you.
          This can make the flow of engineering very rewarding, as I can constantly learn and improve my skills by noticing these patterns.
          <br />
          <br />
          Welcome to my website! I'm currently working at Cisco in the Bay area as a low-level software engineer, working on linux device drivers on UCS Virtual Network Interface Cards (VIC).
        </p>
      </div>

      <img className={styles.Arrow} src={arrow} alt='' />
    </div>
  );
};

export default about;
